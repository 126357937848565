import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">

          <div className="col-lg-4 col-md-6 footer-logo">
              <img src='assets/img/ztax-logo2.png' alt="Ztax Logo" className="img-fluid" />
            </div>

            

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#service">Features</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#team">Clients</a></li>
              </ul>
            </div>
 
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <a href="#webdesign">Web Design</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#webdevelopment">Web Application</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#marketing">Marketing</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#graphic">Graphic Design</a></li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>Machine Coders</h3>
                <p>
                  <strong>Email:</strong> contactus@machinecoders.com<br />
                </p>
                <div className="social-links mt-3">
                  <a href="https://twitter.com" className="twitter"><i className="bx bxl-twitter"></i></a>
                  <a href="https://facebook.com" className="facebook"><i className="bx bxl-facebook"></i></a>
                  <a href="https://instagram.com" className="instagram"><i className="bx bxl-instagram"></i></a>
                  <a href="https://skype.com" className="google-plus"><i className="bx bxl-skype"></i></a>
                  <a href="https://linkedin.com" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
              </div>
            </div>
           

          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>Machine Coders</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="https://machinecoders.com/">Machine Coders</a>
        </div>
      </div>

      <a href="#top" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
     
    </footer>
  );
}

export default Footer;
