import React from 'react';

const teamMembers = [
  {
    name: 'ibnsina-logo',
    title: 'Chief Executive Officer',
    img: 'assets/img/client/ibnsina-logo.jpg',
    social: {
      twitter: 'bi bi-twitter',
      facebook: 'bi bi-facebook',
      instagram: 'bi bi-instagram',
      linkedin: 'bi bi-linkedin',
    },
  },
  {
    name: 'njh-logo',
    title: 'Product Manager',
    img: 'assets/img/client/njh-logo.jpg',
    social: {
      twitter: 'bi bi-twitter',
      facebook: 'bi bi-facebook',
      instagram: 'bi bi-instagram',
      linkedin: 'bi bi-linkedin',
    },
  },
  {
    name: 'aljsafa-logo',
    title: 'CTO',
    img: 'assets/img/client/aljsafa-logo.jpg',
    social: {
      twitter: 'bi bi-twitter',
      facebook: 'bi bi-facebook',
      instagram: 'bi bi-instagram',
      linkedin: 'bi bi-linkedin',
    },
  },
  {
    name: 'bahar-logo',
    title: 'Accountant',
    img: 'assets/img/client/bahar-logo.jpg',
    social: {
      twitter: 'bi bi-twitter',
      facebook: 'bi bi-facebook',
      instagram: 'bi bi-instagram',
      linkedin: 'bi bi-linkedin',
    },
  },
];

const TeamSection = () => {
  return (
    <section id="team" className="team">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Team</h2>
          <p>Take a look at our Clients</p>
        </div>

        <div className="row team-members-container" data-aos="fade-left">
          {teamMembers.map((member, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
                <div className="pic"><img src={member.img} className="img-fluid" alt={member.name} /></div>


            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
