import React from 'react';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import FeaturesSection from './components/FeaturesSection';
import DetailsSection from './components/DetailsSection';
import TeamsSection from './components/TeamsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';


const App = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <AboutSection />
      <FeaturesSection />
      <DetailsSection />
      <TeamsSection />
      <ContactSection/>
      <Footer />
    </div>
  );
}

export default App;
