import React, { useState } from 'react';
 

const ContactSection = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileno: '',
    message: ''
  });

  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Print all form data to the console before submitting
    console.log('Form data before submit:', formData);

    try {
      const response = await fetch('http://localhost/ztax/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)

      });

      // Check if the response is not ok
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Attempt to parse the JSON response
      const data = await response.json();

      // Log the parsed response
      console.log('Response from server:', data);

      // Handle the response based on the status
      if (data.status === 'success') {
        setSuccessMessage(data.message);
        setFormData({  // Clear form data upon success
          name: '',
          email: '',
          mobileno: '',
          message: ''
        });
      } else {
        
        console.error('Error sending email:', data.message);
        setErrorMessage(`Error: ${data.message}`);


      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage(`Error: ${error.message}`);
    } finally {
      setSubmitting(false);
    }

  };

  return (
    <section id="contact" className="contact">
      <div className="container">

        

        <div className="row">
          <div className="col-lg-6" data-aos="fade-right" data-aos-delay="100">
          <div className="section-title" data-aos="fade-up">
            <h2>Contact</h2>
            <p>Contact Us</p>
            <div className="info">Email: <a href="mailto:contact@machinecoders.com">contact@machinecoders.com</a></div>
        </div> 

            <div className="info">
                <h1>Free Trial</h1>
                <h5>We provide a free trail to understand your solution and requirements</h5>
            </div>
          </div>

          <div className="col-lg-6 mt-5 mt-lg-0 icon-box emboss-card" data-aos="fade-left" data-aos-delay="200">
            <form onSubmit={handleSubmit} className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" value={formData.name} className="form-control" id="name" placeholder="Your Name" onChange={handleChange} required />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" value={formData.email} name="email" id="email" placeholder="Your Email" onChange={handleChange} required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="countrycode" className="form-control" id="countrycode" placeholder="Saudi (+966)" required  disabled/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="number" className="form-control" value={formData.mobileno} name="mobileno" id="mobileno" placeholder="500000000"  onChange={handleChange} required />
                </div>
              </div>
              
              <div className="form-group mt-3">
                <textarea className="form-control"  name="message" value={formData.message} rows="5" placeholder="Message" onChange={handleChange} required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit" disabled={submitting}>Book a Demo</button></div>
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </form>
          </div>

        </div>

      </div>
    </section>
  );
};

export default ContactSection;
