import React  from 'react';

const detailsData = [

  {
    imgSrc: 'assets/img/details-1.png',
    imgAlt: 'Why_choose_us',
    imgAos: 'fade-left',
    title: 'Why Choose Us from Other Service Providers ???',
    description: '.',
    paragraphs: [
      'Our team has in-depth knowledge and experience with ZATCA regulations, ensuring that your business remains fully compliant at all times.'
    ], 
    listItems: [
      'Expertise in ZATCA Compliance.',
      'Dedicated Customer Support',
      'Cost-Effective Pricing',
      'Comprehensive Training',
      'Seamless Integration',
      'Advanced Security Measures',
      'User-Centric Design.'
    ],
  },
];

const DetailsSection = () => {
  

  return (
    <section id="details" className="details">
      <div className="container">
        {detailsData.map((detail, index) => (
          <div className="row content" key={index}>
            <div className={`col-md-4 ${index % 2 === 1 ? 'order-1 order-md-2' : ''}`} data-aos={detail.imgAos}>
              <img src={detail.imgSrc} className="img-fluid" alt={detail.imgAlt} />
            </div>
            <div className={`col-md-8 pt-4 ${index % 2 === 1 ? 'order-2 order-md-1' : ''}`} data-aos="fade-up">
              <h3>{detail.title}</h3>
              {detail.description && <p className="fst-italic">{detail.description}</p>}
              {detail.listItems && (
                <ul>
                  {detail.listItems.map((item, i) => (
                    <li key={i}><i className="bi bi-check"></i> {item}</li>
                  ))}
                </ul>
              )}
              {detail.paragraphs && detail.paragraphs.map((p, i) => <p key={i}>{p}</p>)}
              {detail.paragraph && <p>{detail.paragraph}</p>}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DetailsSection;
