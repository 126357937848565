import React from 'react';

const AboutSection = () => {
  return (
    <section id="about" className="team">
      <div className="container">
        {/* <div className="row">
          <div className="col-xl-5 col-lg-6 d-flex justify-content-center align-items-stretch" data-aos="fade-right">
            <a href="https://www.youtube.com/watch?v=StpBR2W8G5A" className="glightbox play-btn mb-4"></a>
          </div>
        </div> */}
       
        {/* <div className="row" >
          <div className=" d-flex justify-content-center align-items-stretch" data-aos="fade-left">
              <div className="pic"><img  src="assets/img/howworks.png" className="img-fluid" alt="howitworks" /></div>
          </div>
        </div> */}

        <div className="section-title" data-aos="fade-up">
          <h2>Our Software WorkFlow</h2>
          <p>How <span style={{backgroundColor: "#FFFF00"}}>Ztax</span> Works?</p>
        </div>


        <div className="row" data-aos="fade-left">
          <div className="col-lg-3 col-md-6">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/step-1.jpg" className="img-fluid" alt="team-1"/></div>
              <div className="member-info">
                <h4>Validate User</h4>
                <span>Ztax compaliance with strong security measures and only authenticated user can login with username and password</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/step-2.jpg" className="img-fluid" alt="team-1"/></div>
              <div className="member-info">
                <h4>Upload Invoice</h4>
                <span>Ztax Supports upload of all your invoice in a minute with our advanced automation E-Invoicing platform</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/step-3.jpg" className="img-fluid" alt="team-1"/></div>
              <div className="member-info">
                <h4>Generate Zatca E-Invoice</h4>
                <span>Automatically generates E-Invoice and validate the according to ZATCA regulations</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/step-4.jpg" className="img-fluid" alt="team-1"/></div>
              <div className="member-info">
                <h4>Intelligence Dashboard</h4>
                <span>Displays all Simplified and Standard tax invoces report in sigle place of intelligence Dashboard</span>
              </div>
            </div>
          </div>

          



        </div>  

      </div>
    </section>
  );
}

export default AboutSection
