import React from 'react';


const featuresData = [
  { icon: 'ri-store-line', color: '#ffbb2c', title: 'Multi-VAT Management' },
  { icon: 'ri-bar-chart-box-line', color: '#5578ff', title: 'One-Click Validation' },
  { icon: 'ri-calendar-todo-line', color: '#e80368', title: 'ZATCA Compliance' },
  { icon: 'ri-paint-brush-line', color: '#e361ff', title: 'Intelligent Dashboard' },
  { icon: 'ri-database-2-line', color: '#47aeff', title: 'API Integration' },
  { icon: 'ri-gradienter-line', color: '#ffa76e', title: 'Automated Invoicing' },
  { icon: 'ri-file-list-3-line', color: '#11dbcf', title: 'Customizable Templates' },
  { icon: 'ri-price-tag-2-line', color: '#4233ff', title: 'Secure Transactions' },
  { icon: 'ri-anchor-line', color: '#b2904f', title: 'Real-Time Reporting' },
  { icon: 'ri-disc-line', color: '#b20969', title: '24/7 Support' },
  { icon: 'ri-base-station-line', color: '#ff5828', title: 'Quick Setup' },
  { icon: 'ri-fingerprint-line', color: '#29cc61', title: 'Error Reduction' },
];

const FeaturesSection = () => {
  return (
    <section id="features" className="features">
      <div className="container">
        <div className="featuressection-title" data-aos="fade-up">
          <h2>Features</h2>
          <p>Check The Features</p>
        </div>
        <div className="row" data-aos="fade-left">
          {featuresData.map((feature, index) => (
            <div key={index} className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box emboss-card" data-aos="zoom-in" data-aos-delay={50 * (index + 1)}>
                <i className={feature.icon} style={{ color: feature.color }}></i>
                <h3><a href="#feature">{feature.title}</a></h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
